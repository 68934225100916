import * as React from "react";

import {
  FaBed,
  FaBriefcaseMedical,
  FaBus,
  FaCar,
  FaHotel,
  FaShoppingBasket,
  FaTaxi,
  FaUtensils,
  FaAmbulance,
} from "react-icons/fa";
import { BiSolidPlaneAlt } from "react-icons/bi";

export const servicesList = [
  {
    alias: "HOTEL",
    title: "Hotel",
    icon: <FaBed size="1rem" color="white" />,
    value: "HOTEL",
  },
  {
    alias: "AVION",
    title: "Avion",
    icon: <BiSolidPlaneAlt size="1rem" color="white" />,
    value: "AVION",
  },
  {
    alias: "TERRESTRE",
    title: "Buses",
    icon: <FaBus size="1rem" color="white" />,
    value: "TERRESTRE",
  },
  {
    alias: "ASISMED",
    title: "Asistencia médica",
    icon: <FaBriefcaseMedical size="1rem" color="white" />,
    value: "ASISMED",
  },
  {
    alias: "ALQVEH",
    title: "Alquiler de véhiculo",
    icon: <FaCar size="1rem" color="white" />,
    value: "ALQVEH",
  },
];

export const outpatientcareServicesList = [
  {
    alias: "HOTEL",
    title: "Hotel",
    icon: <FaBed size="1rem" color="white" />,
    value: "HOTEL",
  },
  {
    alias: "AVION",
    title: "Avion",
    icon: <BiSolidPlaneAlt size="1rem" color="white" />,
    value: "AVION",
  },
  {
    alias: "TERRESTRE",
    title: "Buses",
    icon: <FaBus size="1rem" color="white" />,
    value: "TERRESTRE",
  },
  {
    alias: "AMBULANCIA",
    title: "Ambulancias",
    icon: <FaAmbulance size="1rem" color="white" />,
    value: "AMBULANCIA",
  },
];
export const travelExpensesList = [
  {
    alias: "TRANSPRLOC",
    title: "Transporte Local",
    icon: <FaBus size="1rem" color="white" />,
    value: 1,
  },
  {
    alias: "VLRALIMENT",
    title: "Alimentación",
    icon: <FaUtensils size="1rem" color="white" />,
    value: 2,
  },
  {
    alias: "VLRALOJAM",
    title: "Alojamiento",
    icon: <FaBed size="1rem" color="white" />,
    value: 3,
  },
  {
    alias: "VLRMISCELA",
    title: "Misceláneos",
    icon: <FaShoppingBasket size="1rem" color="white" />,
    value: 4,
  },
  {
    alias: "VLRTAXI",
    title: "Taxis",
    icon: <FaTaxi size="1rem" color="white" />,
    value: 5,
  },
];

export const routesFilter = [
  {
    alias: "HOTEL",
    path: "/reserva-hoteles",
    icon: <FaHotel />,
    name: "Hotel",
  },
  {
    alias: "AVION",
    path: "/reserva-vuelos",
    icon: <BiSolidPlaneAlt size="1.2rem" />,
    name: "Vuelo",
  },
  {
    alias: "TERRESTRE",
    path: "/reserva-buses",
    icon: <FaBus size="1rem" />,
    name: "Buses",
  },
  {
    alias: "TERRESTREESPECIAL",
    path: "/reserva-terrestre",
    icon: <FaCar />,
    name: "Terrestre",
  },
  {
    alias: "ASISMED",
    path: "/reserva-asistencia",
    icon: <FaBriefcaseMedical />,
    name: "Asistencia",
  },
  {
    alias: "ALQVEH",
    path: "/reserva-vehiculos",
    icon: <FaCar />,
    name: "Vehículo",
  },
  {
    alias: "AMBULANCIA",
    path: "/reserva-ambulancia",
    icon: <FaAmbulance />,
    name: "Ambulancia",
  },
];
