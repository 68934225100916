// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.utils-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background: white;
    padding: 20px 25px;
    border-radius: 8px;
    width: 35%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;

    .times {
      position: relative;

      div {
        position: absolute;
        right: 0;
        top: -3px;
        cursor: pointer;
        font-size: 1.5rem;
        color: #9e9e9e;
      }
    }
  }

  .width50 {
    width: 45%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;;EAEb;IACE,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,wCAAwC;IACxC,aAAa;;IAEb;MACE,kBAAkB;;MAElB;QACE,kBAAkB;QAClB,QAAQ;QACR,SAAS;QACT,eAAe;QACf,iBAAiB;QACjB,cAAc;MAChB;IACF;EACF;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".utils-modal-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n\n  .modal-content {\n    background: white;\n    padding: 20px 25px;\n    border-radius: 8px;\n    width: 35%;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    z-index: 1001;\n\n    .times {\n      position: relative;\n\n      div {\n        position: absolute;\n        right: 0;\n        top: -3px;\n        cursor: pointer;\n        font-size: 1.5rem;\n        color: #9e9e9e;\n      }\n    }\n  }\n\n  .width50 {\n    width: 45%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
