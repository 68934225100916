// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.utils-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  .arrow {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0px 5px;
    margin: 0 5px;
    color: #144173;
  }

  .arrow:disabled {
    cursor: not-allowed;
    color: #ccc;
  }

  .page-number {
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
  }

  .page-number.active {
    background-color: #144173;
    color: white;
    border-color: #144173;
  }

  /* .page-number:hover {
      background-color: #ddd;
    } */

  .page-number:disabled {
    cursor: not-allowed;
    /* background-color: #ccc; */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;;EAEd;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;EACvB;;EAEA;;OAEK;;EAEL;IACE,mBAAmB;IACnB,4BAA4B;EAC9B;AACF","sourcesContent":[".utils-pagination {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 10px 0;\n\n  .arrow {\n    background: none;\n    border: none;\n    font-size: 18px;\n    cursor: pointer;\n    padding: 0px 5px;\n    margin: 0 5px;\n    color: #144173;\n  }\n\n  .arrow:disabled {\n    cursor: not-allowed;\n    color: #ccc;\n  }\n\n  .page-number {\n    background-color: #f1f1f1;\n    border: 1px solid #ddd;\n    padding: 5px 10px;\n    margin: 0 5px;\n    cursor: pointer;\n    border-radius: 4px;\n  }\n\n  .page-number.active {\n    background-color: #144173;\n    color: white;\n    border-color: #144173;\n  }\n\n  /* .page-number:hover {\n      background-color: #ddd;\n    } */\n\n  .page-number:disabled {\n    cursor: not-allowed;\n    /* background-color: #ccc; */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
