export const images = {
  logoAsulado: require("./LogoAsulado.png"),
  logoWhite: require("./LogoWhite.png"),
  logoA: require("./asuladoA.png"),
  AvatarAdmin: require("./AvatarAdmin.png"),
  User: require("./User.png"),
  skyImageBackground: require("./sky.jpg"),
  clouds: require("./clouds.png"),
  logoGrupoEcopetrol: require("./logoGrupoEcopetrol.png"),
  logoGrupoEcopetrolCenit: require("./logoGrupoEcopetrolCenit.png"),
  avatar: require("./avatar.png"),
  imageProfile: require("./iconsImage/profile.png"),
  imageRules: require("./iconsImage/rules.png"),
  imageParameters: require("./iconsImage/parameters.png"),
  imageProfileW: require("./iconsImage/profileW.png"),
  imageRulesW: require("./iconsImage/rulesW.svg"),
  imageParametersW: require("./iconsImage/parametersW.png")
};
