import React from 'react';
import './Select.css'

export const SelectAsulado = ({ className, label, value, options, optionCode, optionValue, onChange, placeholder }) => {
    return (
        <div className={className}>
            {label && <label>{label}</label>}
            <select value={value} onChange={onChange}>
                <option value="" disabled>{placeholder || "Seleccione una opción"}</option>

                {options.map((option, index) => (
                    <option key={index} value={option[optionCode]}>
                        {option[optionValue]}
                    </option>
                ))}
            </select>
        </div>
    );
};
