import {
  Card,
  Text,
  Title,
  LoadingOverlay,
  Box,
  Group,
  Radio,
  Paper,
  Flex,
  Overlay,
  Stepper,
  Grid,
  Notification,
  SimpleGrid,
  Modal,
  Button,
  Skeleton,
  Loader,
  TextInput,
  Container,
  Select,
  NumberInput,
} from '@mantine/core';
import {
  FaCheckCircle,
  FaUserAlt,
  FaSearch,
  FaArrowAltCircleLeft,
  FaFilter,
  FaPlus,
} from 'react-icons/fa';
import { AiFillWarning } from 'react-icons/ai';
import { MdOutlineCancel } from 'react-icons/md';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
import React, { useState, useEffect } from 'react';
import { useStyles } from '../styles/index.js';
import { useWindowSize } from '../hooks/use-window-size.tsx';
import { navigateToUrl } from 'single-spa';
import { usePathname } from '../hooks/use-pathname.tsx';
import { routesFilter } from '../resources/index.tsx';
import { DatePicker } from '@mantine/dates';


export const filteredRoutes = (dataFilter) => {
  return routesFilter.filter((route) => {
    return dataFilter
      ?.flatMap((item) => item.ServicioBpo)
      ?.some((item) => item.AliasServicio === route.alias);
  });
};

const getRouteIndex = (order, path) => {
  return order.findIndex((route) => route.path === path);
};

export const orderRoutes = (dataFilter) => {
  const isAid = dataFilter?.TipoProceso === 4;
  const allServicicesBpo = dataFilter?.ViajeTrayecto?.flatMap(
    ({ ServicioBpo }) => ServicioBpo
  );

  const routes = [
    {
      alias: isAid ? 'AUXILIOS' : 'Home',
      path: isAid ? '/auxilios' : '/home',
      icon: <FaArrowAltCircleLeft />,
      name: isAid ? 'Home' : 'Home',
    },
    ...filteredRoutes(dataFilter?.ViajeTrayecto),
  ];

  if (
    !!allServicicesBpo?.length ||
    (isAid && dataFilter?.ViajeTrayecto?.[0]?.auxilioMedico?.Acompanante)
  ) {

  }



  return routes;
};

export const navigateToNextRoute = (servicesData) => {
  const pathname = window.location.pathname;
  const order = orderRoutes(servicesData);

  return navigateToUrl(order[getRouteIndex(order, pathname) + 1].path);
};

export const StepperComponent = ({ servicesData }) => {
  const pathname = usePathname();
  const isXs = useWindowSize('xs');

  const orderRoutesFiltered = orderRoutes(servicesData);

  const handleStepClick = (index) => {
    navigateToUrl(orderRoutesFiltered[index].path);
  };

  return (
    <Skeleton visible={!servicesData?.TipoViajeAlias?.length}>
      <StyledStepper
        active={getRouteIndex(orderRoutesFiltered, pathname)}
        onStepClick={handleStepClick}
        allowNextStepsSelect={false}
        listLength={orderRoutesFiltered.length}
      >
        {orderRoutesFiltered?.map((item, index) => (
          <Stepper.Step
            key={index}
            icon={() =>
              !isXs ? (
                <Flex align='center' gap='12px'>
                  {item.icon}
                  <Text>{item.name}</Text>
                </Flex>
              ) : (
                <>{item.icon}</>
              )
            }
            completedIcon={() =>
              !isXs ? (
                <Flex align='center' gap='12px'>
                  {item.icon}
                  <Text>{item.name}</Text>
                </Flex>
              ) : (
                <>{item.icon}</>
              )
            }
          />
        ))}
      </StyledStepper>
    </Skeleton>
  );
};

export const NotificationComponent = ({
  showNotification,
  requestErrorMessage,
  buttonMessage,
}) => {
  return (
    showNotification && (
      <>
        <Notification
          sx={{
            zIndex: '1000',
            width: '20%',
            position: 'absolute',
            top: '50px',
            right: '50px',
          }}
          withCloseButton={false}
          color='red'
        >
          <SimpleGrid cols={1}>
            <AiFillWarning size='1.5rem' color='red' />
            {requestErrorMessage}
            <Button
              color='red'
              onClick={() => {
                location.reload();
              }}
            >
              {buttonMessage}
            </Button>
          </SimpleGrid>
        </Notification>
        <LoadingOverlay
          visible={true}
          overlayBlur={50}
          loaderProps={{ color: '#cbd300' }}
        />
      </>
    )
  );
};

export const ModalComponent = ({
  opened,
  onClose,
  title,
  children,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <Modal
      size='lg'
      opened={opened}
      onClose={onClose}
      title={title}
      centered
      classNames={{
        header: classes.modalHeader,
        title: classes.modalTitle,
        close: classes.modalClose,
        body: classes.modalBody,
        inner: classes.modalInner,
        overlay: classes.modalOverlay,
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};

export const HeadTitle = ({ title, buttonText, buttonAction, ...props }) => {
  return (
    <Group

      width="100%"
      height="100%"
      bg="#4B4495"
      p={10}
      sx={{ borderRadius: '10px 10px 0px 0px', justifyContent: buttonText?'space-between': 'center' }}
      justify="center"
      align="center"
      {...props}
    >
      <Title order={4} fw="bold" c="white">
        {title}
      </Title>
      {buttonText && buttonAction && (
        <Button bg='white' sx={{color :'#144173'}} type='button' onClick={buttonAction} ml={10} mt={10}>
          {buttonText}
        </Button>
      )}
    </Group>
  );
};

export const FilterComponent = ({
  onAdd,
  onFilter,
  onLogTransactions,
  action,
  filterOptions,
  clearFilters,
  hasFilter,
}) => {
  const [selectedKey, setSelectedKey] = useState(''); // To track the selected filter key
  const [selectedValue, setSelectedValue] = useState(''); // To track the selected filter value
  const { classes } = useStyles(); // Custom styles

  const handleKeyChange = (value) => {
    setSelectedKey(value); // Set the selected key (filter type)
    setSelectedValue(''); // Clear the value when key changes
  };

  const handleValueChange = (value) => setSelectedValue(value); // Set the selected value for the filter

  // This function now uses filterOptions directly to parse the second select options
  const parseOptionsForSecondSelect = (selectedKey) => {
    const selectedFilter = filterOptions.find((option) => option.value === selectedKey);

    // If the selected filter key has options, return them
    if (selectedFilter && selectedFilter.options) {
      return selectedFilter.options.map((opt) => ({
        value: opt.value,
        label: opt.label,
      }));
    }

    return [];
  };

  useEffect(() => {
    renderSecondSelect();
  }, [selectedKey]);

  const renderSecondSelect = () => {
    const options = parseOptionsForSecondSelect(selectedKey); // Get the options based on selectedKey
    return (
      <Select
        data={options} // Pass the options to Mantine Select
        value={selectedValue} // Selected value
        onChange={handleValueChange} // Handle value change
        placeholder={`Seleccionar ${selectedKey}`} // Placeholder text
        sx={{ flex: 1 }} // Flex style
      />
    );
  };

  return (
    <Group my={15} p={20} bg="white">
      <Group sx={{ flex: 0.5 }}>
        {/* First Select for selecting the filter type */}
        <Select
          data={filterOptions} // Directly using filterOptions
          value={selectedKey} // Bind the selected key
          onChange={handleKeyChange} // Handle key change
          placeholder="Filtrar por..." // Placeholder text
          sx={{ flex: 1 }} // Flex style
        />
        {/* Second Select for selecting the value of the selected filter */}
        {renderSecondSelect()}
      </Group>
      <Group sx={{ flex: 1, justifyContent: 'flex-end' }}>
        <Button
          className={classes.butttonActions}
          leftIcon={hasFilter ? <MdOutlineCancel size={16} /> : <FaFilter size={16} />}
          onClick={() => (hasFilter ? clearFilters() : onFilter(selectedKey, selectedValue))}
        >
          {hasFilter ? ' Limpiar filtros ' : 'Filtrar'}
        </Button>
        {onLogTransactions && (
          <Button
            className={classes.butttonActions}
            leftIcon={<BsLayoutTextSidebarReverse size={16} />}
            onClick={onLogTransactions}
          >
            Log de transacciones
          </Button>
        )}
        {onAdd && action === 'EDITAR' && (
          <Button
            className={classes.butttonActionAdd}
            leftIcon={<FaPlus size={16} />}
            onClick={onAdd}
          >
            Agregar
          </Button>
        )}
      </Group>
    </Group>
  );
};

export const FilterLogsComponent = ({
  filters,
  onFilterChange,
  onFilterSubmit,
  optionsCodigoDetalle,
  optionsDetalleTransaccion,
  optionsCanal,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevenir el comportamiento por defecto del formulario
    onFilterSubmit(); // Llamar la función que se pasó por props para hacer la petición
  };

  return (
    <Container bg='white' w='full' p={20} mt={20}>
      {/* Formulario para que podamos prevenir el envío automático */}
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Col span={3}>
            <TextInput
              label='Nombre Solicitante'
              value={filters.nombreSolicitante}
              onChange={(event) =>
                onFilterChange('nombreSolicitante', event.target.value)
              }
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <Select
              label='Código Detalle Transacción'
              value={filters.codigoDetalle}
              data={optionsCodigoDetalle.map((option) => ({
                value: option,
                label: option,
              }))}
              onChange={(value) => onFilterChange('codigoDetalle', value)}
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <Select
              label='Detalle Transacción'
              value={filters.detalleTransaccion}
              data={optionsDetalleTransaccion.map((option) => ({
                value: option,
                label: option,
              }))}
              onChange={(value) => onFilterChange('detalleTransaccion', value)}
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <NumberInput
              label='Número Documento Solicitante'
              value={filters.numeroDocumentoSolicitante}
              onChange={(value) =>
                onFilterChange('numeroDocumentoSolicitante', value)
              }
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <Select
              label='Canal'
              value={filters.canal}
              data={optionsCanal.map((option) => ({
                value: option,
                label: option,
              }))}
              onChange={(value) => onFilterChange('canal', value)}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <DatePicker
              label='Fecha Inicio'
              value={filters.startDate}
              onChange={(date) => onFilterChange('startDate', date)}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <DatePicker
              label='Fecha Fin'
              value={filters.endDate}
              onChange={(date) => onFilterChange('endDate', date)}
            />
          </Grid.Col>
        </Grid>

        {/* Botón para enviar el filtro */}
        <Button mt={20} fullWidth type='submit'>
          Filtrar
        </Button>
      </form>
    </Container>
  );
};


