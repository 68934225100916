import React from 'react'
import './Input.css'

export const InputAsulado = ({ type = 'text', value, onChange, placeholder, label, className, ...props }) => {
  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...props} // Permite pasar otros atributos como `name`, `id`, etc.
      />
    </div>
  );
};