// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.custom-select label {
  display: block;
  margin-bottom: 5px;
}

select {
    font-size: 14px;
    width: 100%;
}

select:not(:disabled) {
  border: 0.1px solid #9E9E9E !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Select/Select.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".custom-select select {\n  padding: 8px;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n}\n\n.custom-select label {\n  display: block;\n  margin-bottom: 5px;\n}\n\nselect {\n    font-size: 14px;\n    width: 100%;\n}\n\nselect:not(:disabled) {\n  border: 0.1px solid #9E9E9E !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
