// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-datepicker input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.custom-datepicker label {
  display: block;
  margin-bottom: 5px;
}

input:not(:disabled) {
    border: 0.1px solid #9E9E9E !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataPicker/DataPicker.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".custom-datepicker input {\n  padding: 8px;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.custom-datepicker label {\n  display: block;\n  margin-bottom: 5px;\n}\n\ninput:not(:disabled) {\n    border: 0.1px solid #9E9E9E !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
