import React from 'react'
import './DataPicker.css'

export const DatePickerAsulado = ({ value, onChange, label, className, ...props }) => {
    return (
        <div className={className}>
            {label && <label>{label}</label>}
            <input
                type="month"
                value={value}
                onChange={onChange}
                {...props} // Permite agregar atributos adicionales como `name`, `id`, `min`, `max`, etc.
            />
        </div>
    )
}