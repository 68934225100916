// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reusable-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
  min-width: 12%;
}

.reusable-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submit-btn {
  background-color: #144173;
}

.submit-btn:hover {
  background-color: #144173;
}

.submit-btn-cancel {
  background-color: #E0E0E0;
  color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,sCAAsC;EACtC,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".reusable-button {\n  padding: 10px 20px;\n  font-size: 14px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  background-color: #007bff;\n  color: white;\n  transition: background-color 0.3s ease;\n  min-width: 12%;\n}\n\n.reusable-button:disabled {\n  background-color: #cccccc;\n  cursor: not-allowed;\n}\n\n.submit-btn {\n  background-color: #144173;\n}\n\n.submit-btn:hover {\n  background-color: #144173;\n}\n\n.submit-btn-cancel {\n  background-color: #E0E0E0;\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
