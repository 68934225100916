// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.utils-table {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    margin-top: 10px;

    tbody {
      tr {
        background-color: white;

        span {
          margin-right: 20px;
        }

        .cursor {
          cursor: pointer;
        }
      }
    }

    th,
    td {
      padding: 10px 20px;
      text-align: left;
    }

    th {
      background-color: white;
      color: #144173;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }

    td {
      font-size: 12px;
      color: #555;
    }
  }

  .table-colors {
    th {
      background-color: #e9ecef;
    }

    tr:nth-child(even) {
      background-color: #f8f9fa; /* filas pares */
    }

    tr:nth-child(odd) {
      background-color: #ffffff; /* filas impares */
    }

    tbody {
      tr {
        border-top: 1px solid #d8e4f7;
      }
    }
  }

  .no-data {
    text-align: center;
    background-color: white;
    padding: 10px;
    margin-top: 20px;
  }
}

.pagination {
  text-align: center;
  background-color: white;
  padding: 1px;
  margin-top: 1px;
  border-radius: 0 0 6px 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;;IAEhB;MACE;QACE,uBAAuB;;QAEvB;UACE,kBAAkB;QACpB;;QAEA;UACE,eAAe;QACjB;MACF;IACF;;IAEA;;MAEE,kBAAkB;MAClB,gBAAgB;IAClB;;IAEA;MACE,uBAAuB;MACvB,cAAc;MACd,gBAAgB;MAChB,eAAe;MACf,mBAAmB;IACrB;;IAEA;MACE,eAAe;MACf,WAAW;IACb;EACF;;EAEA;IACE;MACE,yBAAyB;IAC3B;;IAEA;MACE,yBAAyB,EAAE,gBAAgB;IAC7C;;IAEA;MACE,yBAAyB,EAAE,kBAAkB;IAC/C;;IAEA;MACE;QACE,6BAA6B;MAC/B;IACF;EACF;;EAEA;IACE,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,gBAAgB;EAClB;AACF;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".utils-table {\n  table {\n    width: 100%;\n    border-collapse: collapse;\n    border-radius: 10px;\n    margin-top: 10px;\n\n    tbody {\n      tr {\n        background-color: white;\n\n        span {\n          margin-right: 20px;\n        }\n\n        .cursor {\n          cursor: pointer;\n        }\n      }\n    }\n\n    th,\n    td {\n      padding: 10px 20px;\n      text-align: left;\n    }\n\n    th {\n      background-color: white;\n      color: #144173;\n      font-weight: 500;\n      font-size: 16px;\n      margin-bottom: 10px;\n    }\n\n    td {\n      font-size: 12px;\n      color: #555;\n    }\n  }\n\n  .table-colors {\n    th {\n      background-color: #e9ecef;\n    }\n\n    tr:nth-child(even) {\n      background-color: #f8f9fa; /* filas pares */\n    }\n\n    tr:nth-child(odd) {\n      background-color: #ffffff; /* filas impares */\n    }\n\n    tbody {\n      tr {\n        border-top: 1px solid #d8e4f7;\n      }\n    }\n  }\n\n  .no-data {\n    text-align: center;\n    background-color: white;\n    padding: 10px;\n    margin-top: 20px;\n  }\n}\n\n.pagination {\n  text-align: center;\n  background-color: white;\n  padding: 1px;\n  margin-top: 1px;\n  border-radius: 0 0 6px 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
