import React from 'react'
import './Table.css'

import {
  TbDatabaseOff,
  Text,
} from '@mfe/js-asulado-uiutils';

export const DynamicTable = ({ className, columns, data, actions, typeActions, children }) => {

  return (
    <div className='utils-table'>
      {data?.length > 0 ? (
        <>
          <table className={`${className}`}>
            <thead>
              <tr>
                {columns.map((col) => (
                  <th key={col.key}>{col.label}</th>
                ))}
                {actions
                  ? <th>{actions}</th>
                  : ''}
              </tr>
            </thead>

            <tbody>
              {data.map((row, index) => (
                <tr
                  key={index}>
                  {columns.map((col) => (
                    <td key={col.key}>
                      {col.isMoney
                        ? `$ ${row[col.key].toLocaleString('es-CO')}`
                        : row[col.key]}
                    </td>
                  ))}
                  {actions ? (
                    <td>
                      {typeActions.map((action, index) => (
                        !(row.estadoLiquidacion === 'A' && index === 1) && (
                          <span className='cursor' key={index} onClick={() => action.metodo(row)}>
                            {action.icon}
                          </span>
                        )
                      ))}
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {children}
        </>
      ) : (
        <div className='no-data'>
          <TbDatabaseOff size={100} color='gray' />
          <Text color='gray' mb={10}>
            No hay datos para mostrar
          </Text>
        </div>
      )}
    </div>
  )
}

