import React from 'react'
import './Button.css'

export const ReusableButton = ({ text, onClick, type = 'button', className = '', disabled = false }) => {
    return (
        <button
            type={type}
            className={`reusable-button ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    )
}