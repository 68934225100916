import React from 'react'
import './Pagination.css'

export const PaginationAsulado = ({ currentPage, totalPages, onPageChange }) => {

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      onPageChange(newPage)
    }
  }

  const renderPageNumbers = () => {
    const pages = []
    const startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(totalPages, currentPage + 1)

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`page-number ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      )
    }
    return pages
  }

  return (
    <div className="utils-pagination">
      <button
        className="arrow"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      >
        &laquo; {/* Flecha doble*/}
      </button>

      <button
        className="arrow"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lt; {/* Flecha simple */}
      </button>

      {renderPageNumbers()}

      <button
        className="arrow"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &gt; {/* Flecha simple*/}
      </button>

      <button
        className="arrow"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        &raquo; {/* Flecha doble*/}
      </button>

    </div>
  )
}
