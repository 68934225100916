
import React from 'react';
import './Modal.css';

export const ModalAsulado = ({title, rowData, children, className = '' }) => {
    // if (!isOpen) return null

    return (
        <div className="utils-modal-overlay">
            <div className={`modal-content ${className}`}>
                {/* <div className='times'>
                    <div onClick={onClose}>
                        &times;
                    </div>
                </div> */}

                <div className="">
                    {title}
                </div>

                {children}
            </div>
        </div >
    )
}