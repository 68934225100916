import { createStyles, keyframes, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  //HEADER COMPONENT
  inner: {
    height: rem(56),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'red',
  },
  textState: {
    fontSize: '18px',
    color: '#ed7703',
  },

  burger: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
  link: {
    'display': 'block',
    'lineHeight': 1,
    'padding': `${rem(8)} ${rem(12)}`,
    'borderRadius': theme.radius.sm,
    'textDecoration': 'none',
    'color': '#004236',
    'fontSize': theme.fontSizes.sm,
    'fontWeight': 'bolder',
    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  linkActive: {
    '&, &:hover': {
      backgroundColor: '#CBD300',
    },
  },
  linkLabel: {
    marginRight: rem(5),
  },
  // FOOTER COMPONENT
  footer: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    [theme.fn.smallerThan('md')]: {
      position: 'relative',
    },
  },
  //TRAVEL COMPONENT
  dropdown: { marginTop: '0.5rem' },
  rightSection: { pointerEvents: 'none' },
  label: {
    color: 'white',
  },
  labelSecondary: {
    color: '#004236',
    fontWeight: 'bold',
  },
  input: {
    border: '1px solid #00add0',
    '&:focus-within': {
      border: '2px solid #F6BB81',
    },
    '&[data-disabled]': {
      color: 'black',
      backgroundColor: '#d3d3d3',
      border: 'none',
    },
  },
  item: {
    margin:'5px 0',
    borderRadius:'3px',
    zIndex:1000,
    '&:hover': {
      backgroundColor: '#2ABBDC',
      color: 'white',
      transform: 'scale(1.1)',
      width: '105%',
      animationDuration: '.3s',
      animationTimingFunction: 'cubic-bezier(.4,0,.2,1)',
      transitionDuration: '.3s',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
      zIndex: 1000,
    }
  },
  button: {
    'backgroundColor': '#2EBADA',
    'color': '#004236',
    '&:hover': {
      backgroundColor: '#144173',
      color: 'white',
      filter: 'brightness(96%)',
    },
  },
  buttonOutline: {
    'color': '#273e82',
    'borderColor': '#004236',
    'backgroundColor': 'transparent',
    '&:hover': {
      backgroundColor: '#FBFBFB',
      filter: 'brightness(98%)',
    },
  },
  butttonActions: {
    'backgroundColor': '#144173',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#144178',
      color: 'white',
      filter: 'brightness(96%)',
    },
  },
  butttonActionAdd: {
    'backgroundColor': '#4B4495',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#4B4498',
      color: 'white',
      filter: 'brightness(96%)',
    },
  },
  ovf: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
  checkBox: {
    margin: '1rem 0',
    input: {
      '&:checked': {
        backgroundColor: '#cbd300',
        border: '2px solid #cbd300',
      },
      'border': '2px solid #cbd300',
    },
  },
  rootSegmentedControl: {
    'backgroundColor': 'transparent',
    '&:not([data-active])': {
      label: {
        'color': '#fff',
        '&:hover': {
          color: '#cbd300',
        },
      },
    },
    'label': {
      '&[data-active]': {
        color: '#cbd300',
      },
    },
  },
  indicatorSegmentedControl: {
    backgroundColor: 'rgba(255,255,255,.1)',
  },
  calendar: {
    '&[data-selected], &[data-selected]:hover': {
      backgroundColor: '#cbd300',
      color: 'white',
    },
    '&[data-in-range], &[data-in-range]:hover': {
      backgroundColor: '#cbd300',
      color: 'white',
      opacity: '0.5',
    },
    '&[data-first-in-range], &[data-first-in-range]:hover': {
      backgroundColor: '#cbd300',
      color: 'white',
      opacity: '1',
    },
    '&[data-last-in-range], &[data-last-in-range]:hover': {
      backgroundColor: '#cbd300',
      color: 'white',
      opacity: '1',
    },
  },
  // LOGIN
  lineGradientEcopetrol: {
    height: '20px',
    background:
      'linear-gradient(to right, #004237 0%, #004237 50%, #ffdd00 50%, #ffdd00 100%)',
  },
  lineGradientCenit: {
    height: '20px',
    background:
      'linear-gradient(to right, #14334D 0%, #14334D 50%, #D3CF37 50%, #D3CF37 100%)',
  },
  // BACKGROUND IMAGE
  backgroundImage: {
    minHeight: 'calc(100vh - 135px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
    boxSizing: 'border-box',
    paddingBlock: '3rem',
  },
  clouds: {
    animation: `${cloudsAnimation} 60s linear infinite`,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  alignVerticallyCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textShadow: {
    textShadow: 'black 0.1em 0.1em 0.2em',
  },
  logoFligth: {
    width: '60px',
    height: '60px',
    maxWidth: '400px',
  },
  flightCard: {
    padding: theme.spacing.md,
    marginBottom: theme.spacing.md,
  },
  flightInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.xs,
  },
  modalOverlay: {
    zIndex: 300,
  },
  modalHeader: {
    backgroundColor: '#2EBADA',
  },
  modalTitle: {
    fontWeight: 700,
    color: '#004236',
  },
  modalClose: {
    'color': '#004236',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  modalBody: {
    '&:not(:only-child)': {
      paddingTop: '1rem',
    },
    'paddingTop': '1rem',
    'fontWeight': 400,
  },
  modalInner: {
    padding: 0,
    zIndex: 400,
  },
  tabsList: { gap: '12px', borderBottom: 'none' },
  tab: {
    'position': 'relative',
    'backgroundColor': '#c3c5c6',
    'borderRadius': '20px',
    'color': '#004236',
    'borderBottom': 'none',
    '& + &': {
      borderLeftWidth: 0,
      ...(theme.rtl && {
        borderRightWidth: 0,
        borderLeftWidth: '1px',
      }),
    },
    '&:hover': {
      backgroundColor: '#cbd300',
      color: '#004236',
      borderColor: '#004236',
    },
    '&[data-active]': {
      'zIndex': 1,
      'color': '#004236',
      'fontWeight': '400',
      'backgroundColor': '#cbd300',
      'borderColor': '#cbd300',
      '&:hover': {
        backgroundColor: '#cbd300',
        borderColor: '#004236',
      },
    },
  },
  radioButton: {
    input: {
      '&:checked': {
        backgroundColor: '#cbd300',
        borderColor: '#cbd300',
      },
    },
  },
  drawerOverlay: {
    zIndex: 300,
  },
  drawerInner: {
    zIndex: 400,
  },
  table: {
    borderRadius: '10px 10px',
    backgroundColor: 'white',
    padding: theme.spacing.md,
  },
  accordion: {
    'backgroundColor':
      'light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6))',
    'border': '1px solid transparent',
    'position': 'relative',
    'zIndex': 0,
    'transition': 'transform 150ms ease',

    '&[data-active]': {
      transform: 'scale(1.03)',
      zIndex: 1,
      backgroundColor: 'var(--mantine-color-body)',
      borderColor:
        'light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4))',
      boxShadow: 'var(--mantine-shadow-md)',
      borderRadius: ' var(--mantine-radius-md)',
    },
  },
  accordionMenu: {
    'backgroundColor': 'transparent',
    'border': '1px solid transparent',
    'width': '100%',
    'color': 'white',
    'position': 'relative',
    'borderRadius': '10px 10px',
    'zIndex': 0,
    'transition': 'transform 150ms ease',
    height:'50px',
    '&[data-active]': {
      transform: 'scale(1.03)',
      zIndex: 1,
      backgroundColor: 'transparent',
      borderColor:
        'transparent',
      boxShadow: 'var(--mantine-shadow-md)',
      borderRadius: ' var(--mantine-radius-md)',
    },

    '&:hover': {
      backgroundColor: 'transparent',
      fontWeight: 'bold',
    },
  },
  hamburguer: {
    position: 'absolute',
    borderRadius: '0 5px 5px 0',
    padding: '2px 5px 2px 5px',
    zIndex: '100',
    top: '35px',
    fontSize: '25px',
    right: '-15px',
    color: 'white',
    cursor: 'pointer',
  },
}));

const cloudsAnimation = keyframes({
  '0%': { transform: 'translateX(0)' },
  '50%': { transform: 'translateX(100%)' },
  '100%': { transform: 'translateX(0)' },
});
